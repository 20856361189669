import React, { useEffect, useState } from 'react'
import {
  EligibleGroupInterface,
  ReviewCyclesInterface,
  ReviewCycleStage,
} from '@src/interfaces/reviewCycles'
import { VStack } from '@revolut/ui-kit'
import { TimelineModel } from '@src/pages/Forms/ReviewCycle/ReviewCycle/models/TimelineModel'
import { StageWidget } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/StageWidget'
import { ProgressWidget } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/ProgressWidget/ProgressWidget'
import { SidebarProvider } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Sidebar/SidebarProvider'
import { PerformanceSettingsInterface } from '@src/interfaces/settings'
import { useStageHistory } from '@src/pages/Forms/ReviewCycle/ReviewCycle/hooks/useStageHistory'

interface Props {
  cycle: ReviewCyclesInterface
  stages: ReviewCycleStage[]
  eligibleGroups: EligibleGroupInterface[]
  settings?: PerformanceSettingsInterface
}

export const CycleTimeline = ({ cycle, stages, eligibleGroups, settings }: Props) => {
  const { stage: stageFromParam, update } = useStageHistory(cycle)
  const [selectedStage, setSelectedStage] = useState(
    stageFromParam ?? TimelineModel.getCurrentStage(cycle, stages),
  )

  useEffect(() => {
    if (stageFromParam && stageFromParam !== selectedStage) {
      setSelectedStage(stageFromParam)
    }
  }, [stageFromParam])

  const makeStageClickHandler = (stage: ReviewCycleStage) => () => {
    setSelectedStage(stage)
    update(stage)
  }

  return (
    <SidebarProvider cycle={cycle} stage={selectedStage}>
      <VStack space="s-8">
        <ProgressWidget
          cycle={cycle}
          stages={stages}
          selectedStage={selectedStage}
          handleSelectionChange={makeStageClickHandler}
        />
        <StageWidget
          cycle={cycle}
          eligibleGroups={eligibleGroups}
          stage={selectedStage}
          settings={settings}
        />
      </VStack>
    </SidebarProvider>
  )
}

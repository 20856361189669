import React from 'react'
import { useSelector } from 'react-redux'
import { useQueryClient } from 'react-query'
import { Avatar, Flex, Token } from '@revolut/ui-kit'
import { API } from '@src/constants/api'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { PageActions } from '@src/components/Page/PageActions'
import { PageBody } from '@src/components/Page/PageBody'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { Recommended } from '@src/pages/OnboardingChecklist/components/Recommended'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'

export const General = () => {
  const queryClient = useQueryClient()
  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(PermissionTypes.EnabledTotalCompensationApp)

  return (
    <>
      <PageBody>
        <AutoStepper>
          <NewStepperTitle
            title="General settings"
            subtitle="Settings which apply for all compensation functionalities"
          />
          <LapeNewSwitch
            disabled={disableEdit}
            itemTypeProps={{
              avatar: <Avatar color={Token.color.blue} size={40} useIcon="CreditBag" />,
              description:
                'You can choose to enable or disable compensation app across the product',
              title: (
                <Flex gap="s-8">
                  Enable the Compensation app <Recommended />
                </Flex>
              ),
            }}
            name="enabled"
          />
        </AutoStepper>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          onAfterSubmit={() => {
            queryClient.invalidateQueries(API.COMPENSATION_SETTINGS)
            queryClient.invalidateQueries(API.APP_SETTINGS)
          }}
          successText="Settings saved"
          useValidator
        />
      </PageActions>
    </>
  )
}

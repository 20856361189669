import Form from '@src/features/Form/Form'
import { clickupIntegrationPreferences } from '@src/api/integrations'
import React, { useEffect, useState } from 'react'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@components/Page/PageBody'
import {
  Text,
  Avatar,
  Spacer,
  Token,
  HStack,
  VStack,
  Box,
  Item,
  Icon,
  Flex,
  ActionButton,
} from '@revolut/ui-kit'
import { PageActions } from '@components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { API } from '@src/constants/api'
import { useQueryClient } from 'react-query'
import { LocalStorageKeys } from '@src/store/auth/types'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ClickupIntegrationInterface } from '@src/interfaces/integrations'

import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { IntegrationEnablePopup } from '../components/IntegrationEnablePopup'
import { OnboardingIntro } from '@src/components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTextWidget } from '@src/components/OnboardingIntro/OnboardingIntroTextWidget'
import { FormattedMessage } from 'react-intl'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { OnboardingIntroTips } from '@src/components/OnboardingIntro/OnboardingIntroTips'

export const ClickupIntegration = () => {
  return (
    <Form api={clickupIntegrationPreferences} forceParams={{ id: '1' }}>
      <ClickUpForm />
    </Form>
  )
}

const ClickUpForm = () => {
  const queryClient = useQueryClient()
  const { values, reset, submit, isSubmitting, loading } =
    useLapeContext<ClickupIntegrationInterface>()
  const confirmationPopup = useConfirmationDialog()
  const [enableIntegrationPopupOpen, setEnableIntegrationPopupOpen] = useState(false)
  const showStatusPopup = useShowStatusPopup()

  useEffect(() => {
    const integrationConnected = workspaceLocalStorage.getItem(
      LocalStorageKeys.CONNECTED_INTEGRATION,
    )
    if (integrationConnected) {
      showStatusPopup({
        status: 'success',
        title: `${integrationConnected} integration successful`,
      })
      workspaceLocalStorage.removeItem(LocalStorageKeys.CONNECTED_INTEGRATION)
    }
  }, [])

  return (
    <PageWrapper>
      <PageHeader
        title="ClickUp"
        backUrl={pathToUrl(ROUTES.FEATURES.INTEGRATIONS)}
        subtitle={
          <VStack maxWidth={{ all: '100%', md: Token.breakpoint.md }} pb="s-4">
            <HStack align="end">
              <VStack>
                {values.enabled && (
                  <Text variant="emphasis1" color={Token.color.success}>
                    Connected
                  </Text>
                )}
                <Text color={Token.color.greyTone50} variant="body2">
                  Collaboration
                </Text>
              </VStack>
              <Spacer />
              <Avatar
                image={{
                  default: 'https://assets.revolut.com/assets/apps/ClickUp.webp',
                  '2x': 'https://assets.revolut.com/assets/apps/ClickUp@2x.webp',
                  '3x': 'https://assets.revolut.com/assets/apps/ClickUp@3x.webp',
                }}
                size={56}
              >
                {values.enabled ? (
                  <Avatar.Badge useIcon="Check" bg={Token.color.success} />
                ) : null}
              </Avatar>
            </HStack>
          </VStack>
        }
      />

      {!values.enabled && (
        <Box pt="s-16">
          <ActionButton
            useIcon="Link"
            pending={loading}
            disabled={loading || isSubmitting}
            variant="accent"
            onClick={evnt => {
              evnt.preventDefault()
              setEnableIntegrationPopupOpen(true)
            }}
          >
            Enable
          </ActionButton>
        </Box>
      )}

      <PageBody mb="s-64" mt="s-16" gap="s-16">
        <OnboardingIntro>
          <OnboardingIntroTextWidget
            title={
              <FormattedMessage
                id="integrations.clickup.intro.title"
                defaultMessage="About integration"
              />
            }
          >
            <p>
              <FormattedMessage
                id="integrations.clickup.intro.text"
                defaultMessage="Easily connect all your ClickUp tasks to automatically track the progress and achievement of your Goals within Revolut People Performance app"
              />
            </p>
          </OnboardingIntroTextWidget>
        </OnboardingIntro>
        <OnboardingIntroTips
          title={
            <FormattedMessage
              id="integrations.clickup.intro.tips.title"
              defaultMessage="How to use"
            />
          }
          items={[
            {
              title: (
                <FormattedMessage
                  id="integrations.clickup.intro.tips.integrate.title"
                  defaultMessage="Enable ClickUp integration"
                />
              ),
              icon: 'Link',
              text: (
                <FormattedMessage
                  id="integrations.jira.intro.tips.integrate.text"
                  defaultMessage="Use the “Enable” button above and provide the information needed"
                />
              ),
            },
            {
              title: (
                <FormattedMessage
                  id="integrations.clickup.intro.tips.sync.title"
                  defaultMessage="Ensure the Goals and Roadmaps can be managed based on ClickUp"
                />
              ),
              icon: 'Gear',
              text: (
                <FormattedMessage
                  id="integrations.jira.intro.tips.sync.text"
                  defaultMessage="Go to <a>Goals Settings -> Roadmaps</a> and switch on “Enable Roadmaps” option. And then “Manage Goals and Roadmaps based on ClickUp”"
                  values={{
                    a: chunks => (
                      <InternalLink to={ROUTES.SETTINGS.GOALS.ROADMAPS}>
                        {chunks}
                      </InternalLink>
                    ),
                  }}
                />
              ),
            },
            {
              title: (
                <FormattedMessage
                  id="integrations.jira.intro.tips.roadmap.title"
                  defaultMessage="Attach roadmap to your team or department"
                />
              ),
              icon: 'Target',
              text: (
                <FormattedMessage
                  id="integrations.jira.intro.tips.roadmap.text"
                  defaultMessage="Team and department owners can attach roadmaps to their units."
                />
              ),
            },
          ]}
        />
        {values.enabled && (
          <Item
            color={Token.color.danger}
            use="button"
            onClick={async evnt => {
              evnt.preventDefault()
              confirmationPopup.show({
                label: 'Are you sure?',
                body: 'After disabling this integration you won’t be able to use ClickUp as a tracking tool',
                onConfirm: async () => {
                  values.enabled = false
                  try {
                    const response = await submit()
                    showStatusPopup({ title: 'Integration disabled' })
                    reset(response)
                  } catch (error) {
                    showStatusPopup({
                      title: 'Cannot disable integration',
                      description: getStringMessageFromError(error),
                      status: 'error',
                    })
                    values.enabled = true
                  }
                },
              })
            }}
          >
            <Item.Content>
              <Flex gap="s-16">
                <Icon name="Unlink" />
                <Item.Title>Disconnect ClickUp</Item.Title>
              </Flex>
            </Item.Content>
          </Item>
        )}
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          successText="Settings saved successfully"
          onAfterSubmit={() => {
            queryClient.invalidateQueries(API.CLICKUP_INTEGRATION_PREFERENCES)
          }}
          useValidator
        />
      </PageActions>
      <IntegrationEnablePopup
        isOpen={enableIntegrationPopupOpen}
        type={'clickup'}
        onClose={() => {
          setEnableIntegrationPopupOpen(false)
        }}
      />
    </PageWrapper>
  )
}

import { Flex, MoreBar, MoreBarSkeleton, VStack } from '@revolut/ui-kit'
import { goalsListTableRequests, GoalsStats, ManageGoalsPayload } from '@src/api/goals'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { useTable } from '@src/components/Table/hooks'
import { goalsWeightColumn } from '@src/constants/columns/goals'
import { TableNames } from '@src/constants/table'
import { Statuses } from '@src/interfaces'
import { FilterByInterface, RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { EmployeeInterface } from '@src/interfaces/employees'
import { GoalsInterface } from '@src/interfaces/goals'
import { ProbationCheckpoints } from '@src/interfaces/probationReview'
import React from 'react'
import ApproveGoalsWidget from '../ApproveGoalsWidget'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { PermissionTypes } from '@src/store/auth/types'
import { BulkGoalApproveAction } from '@src/features/Goals/components/BulkGoalApproveAction'
import { AddGoal } from './AddGoal'
import { getRows } from './getRows'
import { useManageGoalsWeights } from '@src/features/Goals/useManageGoalsWeights'
import LapeEditableTable from '@src/components/TableV2/EditableTable/LapeEditableTable'
import LapeForm, { useLapeContext } from '@src/features/Form/LapeForm'
import { EntityTypes } from '@src/constants/api'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import Table from '@src/components/TableV2/Table'
import { useGetGoalContentTypeFilter } from '@src/features/Goals/useGoalFilters'
import Loader from '@src/components/CommonSC/Loader'

interface Props {
  data: EmployeeInterface
  cycleId: string
  checkpoints?: ProbationCheckpoints
  contentTypeFilter: FilterByInterface | null
  refetch: () => Promise<any>
}

const ProbationGoalsTableBody = ({
  data: employee,
  cycleId,
  checkpoints,
  contentTypeFilter,
  refetch,
}: Props) => {
  const { data: performanceSettings } = useGetPerformanceSettings()

  const isMultipleGoalsTargetsEnabled =
    !!performanceSettings?.enable_multiple_goal_targets_per_cycle

  const form = useLapeContext<ManageGoalsPayload>()
  const table = useTable(
    goalsListTableRequests,
    [
      ...(contentTypeFilter ? [contentTypeFilter] : []),
      {
        columnName: 'cycle__id',
        filters: [{ id: cycleId, name: cycleId }],
        nonInheritable: true,
        nonResettable: true,
      },
      {
        columnName: 'object_id',
        filters: [{ id: employee.id, name: String(employee.id) }],
        nonInheritable: true,
        nonResettable: true,
      },
    ],
    [{ sortBy: String(goalsWeightColumn.sortKey), direction: SORT_DIRECTION.ASC }],
    {
      parentIdFilterKey: 'parent_id',
    },
  )
  const {
    manageMode,
    handleSubmit,
    toggleManageMode,
    confirmationDialog,
    autoDistributeWeights,
    validationMessage,
  } = useManageGoalsWeights({
    entity: { type: EntityTypes.employee, data: employee },
    contentType: table.data?.[0]?.content_type?.id,
    tableData: table.data || [],
    cycleId,
    cycleCategory: ReviewCycleCategory.Probation,
    onUpdated: () => table.refresh(),
  })

  const canManageGoals = !!employee.field_options.permissions?.includes(
    PermissionTypes.CanManageGoals,
  )

  const checkpoint = checkpoints?.checkpoints?.[0]
  const isPendingApproval = checkpoint?.goals?.status === Statuses.pending_approval

  const goalsIdsToApprove = table.data
    ?.filter(
      ({ approval_status, status }) =>
        ![Statuses.draft, Statuses.archived].includes(status.id) &&
        approval_status.id !== ApprovalStatuses.Approved,
    )
    .map(({ id }) => ({ id }))

  return (
    <VStack space="s-16">
      {isPendingApproval && checkpoint && (
        <ApproveGoalsWidget
          employee={employee}
          refetch={refetch}
          checkpoint={checkpoint}
        />
      )}
      <Table.Widget>
        <Table.Widget.Actions>
          {table.loading ? (
            <MoreBarSkeleton />
          ) : (
            <Table.Widget.MoreBar>
              <BulkGoalApproveAction
                goals={goalsIdsToApprove}
                onApproved={table.refresh}
                title={
                  goalsIdsToApprove.length === table.data.length
                    ? 'Approve all goals'
                    : undefined
                }
              />
              <AddGoal employee={employee} cycleId={cycleId} />
              {table.data.length > 0 && canManageGoals && (
                <MoreBar.Action
                  useIcon={manageMode ? 'SwitchOn' : 'SwitchOff'}
                  onClick={toggleManageMode}
                >
                  Edit weights
                </MoreBar.Action>
              )}
              {manageMode && (
                <MoreBar.Action useIcon="AutoExchange" onClick={autoDistributeWeights}>
                  Auto-distribute weights
                </MoreBar.Action>
              )}
            </Table.Widget.MoreBar>
          )}
        </Table.Widget.Actions>
        <Table.Widget.Table>
          {manageMode ? (
            <LapeEditableTable<GoalsInterface>
              dataFieldName="goals"
              name={TableNames.Goals}
              dataType="Goal"
              disableFilters
              {...table}
              initialData={table.data}
              row={getRows({
                isMultipleGoalsTargetsEnabled,
                canManageGoals,
                updateRows: table.updateRows,
                isManagingWeights: true,
              })}
              useWindowScroll
              replaceOnInitialDataChange
            />
          ) : (
            <AdjustableTable<GoalsInterface, GoalsStats>
              name={TableNames.Goals}
              dataType="Goal"
              {...table}
              row={
                getRows({
                  isMultipleGoalsTargetsEnabled,
                  canManageGoals,
                  updateRows: table.updateRows,
                  isManagingWeights: false,
                }) as RowInterface<GoalsInterface>
              }
              expandableType="chevron"
              useFetchedChildren
              useWindowScroll
            />
          )}
        </Table.Widget.Table>
      </Table.Widget>
      {manageMode && !table.loading && table.data.length ? (
        <Flex height="s-20">{validationMessage}</Flex>
      ) : null}
      {manageMode && form.dirty && (
        <PageActions maxWidth="100%">
          <NewSaveButtonWithPopup
            disabled={!!validationMessage}
            onClick={() => handleSubmit()}
            successText="Goal weights have been updated"
          >
            Submit
          </NewSaveButtonWithPopup>
        </PageActions>
      )}
      {confirmationDialog}
    </VStack>
  )
}

export const ProbationGoalsTable = (props: Props) => {
  const { filter, isLoading } = useGetGoalContentTypeFilter(EntityTypes.employee)
  return (
    <LapeForm
      disableValidation
      onSubmit={() => Promise.resolve({})}
      initialValues={{
        goals: [],
        goal_weight_mode: { id: 'automatic' },
      }}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <ProbationGoalsTableBody {...props} contentTypeFilter={filter} />
      )}
    </LapeForm>
  )
}

import { ReviewCyclesInterface, ReviewCycleStage } from '@src/interfaces/reviewCycles'
import useFetchOptions from '@components/Inputs/hooks/useFetchOptions'
import { IdAndName } from '@src/interfaces'
import { API, selectorKeys } from '@src/constants/api'
import { TimelineModel } from '@src/pages/Forms/ReviewCycle/ReviewCycle/models/TimelineModel'
import { useSwitchCycleStage } from '@src/api/reviewCycles'
import { useQueryClient } from 'react-query'

interface StageSwitcher {
  isLoading: boolean
  next: () => Promise<ReviewCycleStage | null>
}

export const useSwitchStage = (cycle: ReviewCyclesInterface): StageSwitcher => {
  const queryClient = useQueryClient()
  const { options: availableStages, asyncState } = useFetchOptions<
    IdAndName<ReviewCycleStage>
  >(selectorKeys.performance_cycle_stages)
  const { mutateAsync: switchStage, isLoading: isSwitching } = useSwitchCycleStage(
    cycle.id,
  )

  const stages = availableStages ? availableStages.map(({ value: { id } }) => id) : []

  const next = async (): Promise<ReviewCycleStage | null> => {
    const nextStage = TimelineModel.getNextStage(cycle, stages)

    if (nextStage === null) {
      return null
    }

    await switchStage(
      {
        current_stage: nextStage,
      },
      {
        onSuccess: () => {
          queryClient.setQueryData<ReviewCyclesInterface>(
            [`${API.REVIEW_CYCLES}/${cycle.id}`, 'v1', null],
            oldData => ({ ...oldData!, current_stage: nextStage }),
          )
        },
      },
    )

    return nextStage
  }

  return {
    isLoading: asyncState === 'pending' || isSwitching,
    next,
  }
}

import { Flex, Input } from '@revolut/ui-kit'
import {
  QueryParameterInterface,
  QueryParameterTypes,
} from '@src/interfaces/dataAnalytics'
import React, { useContext, useEffect, useState } from 'react'
import { QueryContext } from '@src/pages/Forms/QueryForm/QueryContextProvider'
import { DatePickerInput } from '@components/Inputs/DatePickerInput/DatePickerInput'

interface TextParameterProps {
  defaultValue: string | null
  name: string
}

interface DateParameterProps {
  defaultValue: Date | null
  name: string
}

interface MetricsItemParametersListProps {
  parameters: QueryParameterInterface[]
}
const INPUT_WIDTH = 216

const DateParameter = ({ defaultValue, name }: DateParameterProps) => {
  const { resetParameters, setQueryRunParameters, queryRunParameters } =
    useContext(QueryContext)
  const [inputValue, setInputValue] = useState(queryRunParameters[name] || defaultValue)

  useEffect(() => {
    if (resetParameters && !inputValue) {
      setInputValue(defaultValue)
    }
  }, [resetParameters])

  return (
    <DatePickerInput
      label={name}
      onChange={date => {
        setInputValue(date)
        setQueryRunParameters(prevValue => ({
          ...prevValue,
          [name]: date || defaultValue,
        }))
      }}
      value={inputValue}
      width={INPUT_WIDTH}
    />
  )
}

const TextParameter = ({ defaultValue, name }: TextParameterProps) => {
  const { resetParameters, setQueryRunParameters, queryRunParameters } =
    useContext(QueryContext)

  const [inputValue, setInputValue] = useState(
    queryRunParameters[name]?.toString() || defaultValue || '',
  )

  useEffect(() => {
    if (resetParameters && !inputValue) {
      setInputValue(defaultValue || '')
    }
  }, [resetParameters])

  return (
    <Input
      label={name}
      name={name}
      onChange={e => {
        const { value } = e.currentTarget
        setInputValue(value)
        if (queryRunParameters[name] !== value) {
          setQueryRunParameters(prevValue => {
            return {
              ...prevValue,
              [name]: value || defaultValue,
            }
          })
        }
      }}
      onClear={() => {
        setInputValue('')
        setQueryRunParameters(prevValue => ({
          ...prevValue,
          [name]: defaultValue,
        }))
      }}
      value={inputValue}
      width={INPUT_WIDTH}
    />
  )
}

export const MetricsItemParametersList = ({
  parameters,
}: MetricsItemParametersListProps) => {
  return (
    <Flex flexWrap="wrap" gap="s-4" mb="s-16" overflow="auto" height="fit-content">
      {parameters.map((parameter: QueryParameterInterface) => {
        if (parameter.type === QueryParameterTypes.Date) {
          return (
            <DateParameter
              defaultValue={parameter.default as Date | null}
              key={parameter.name}
              name={parameter.name}
            />
          )
        }
        return (
          <TextParameter
            defaultValue={parameter.default as string | null}
            key={parameter.name}
            name={parameter.name}
          />
        )
      })}
    </Flex>
  )
}

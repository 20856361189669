import React from 'react'
import { API } from '@src/constants/api'
import {
  importGrantsCurrencyColumn,
  importGrantsEmployeeIdColumn,
  importGrantsExternalGrantIdColumn,
  importGrantsGrantDateColumn,
  importGrantsGrantTransactionTypeColumn,
  importGrantsGrantTransactionQuantityColumn,
  importGrantsReasonColumn,
  importGrantsStockOptionsGrantedColumn,
  importGrantsStockOptionUnitValueColumn,
  importGrantsStockOptionVestEffectiveDateColumn,
  importGrantsVestingScheduleDescriptionColumn,
  importGrantsVestingStartDateColumn,
} from '@src/constants/columns/importGrants'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { RowInterface } from '@src/interfaces/data'
import { ImportGrantsInterface } from '@src/interfaces/importGrants'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { BulkDataUploadV2 } from '@src/features/BulkDataUpload'
import { CommonGenericEditableTableRowOptions } from '@src/features/GenericEditableTable/GenericEditableTable'

const row = (
  options: CommonGenericEditableTableRowOptions,
): RowInterface<ImportInterface<ImportGrantsInterface>> => ({
  cells: [
    {
      ...importGrantsEmployeeIdColumn(options.onChange),
      width: 200,
    },
    {
      ...importGrantsExternalGrantIdColumn(options.onChange),
      width: 200,
    },
    {
      ...importGrantsReasonColumn(options.onChange),
      width: 200,
    },
    {
      ...importGrantsVestingScheduleDescriptionColumn(options.onChange),
      width: 200,
    },
    {
      ...importGrantsGrantDateColumn(options.onChange),
      width: 200,
    },
    {
      ...importGrantsVestingStartDateColumn(options.onChange),
      width: 200,
    },
    {
      ...importGrantsStockOptionsGrantedColumn(options.onChange),
      width: 200,
    },
    {
      ...importGrantsGrantTransactionTypeColumn(options.onChange),
      width: 200,
    },
    {
      ...importGrantsGrantTransactionQuantityColumn(options.onChange),
      width: 200,
    },
    {
      ...importGrantsStockOptionUnitValueColumn(options.onChange),
      width: 200,
    },
    {
      ...importGrantsCurrencyColumn(options.onChange),
      width: 200,
    },
    {
      ...importGrantsStockOptionVestEffectiveDateColumn(options.onChange),
      width: 200,
    },
  ],
})

export const ImportGrants = () => {
  return (
    <BulkDataUploadV2
      apiEndpoint={API.GRANTS_BULK_UPLOAD}
      anyRoute={ROUTES.FORMS.IMPORT_DATA.GRANTS.ANY}
      importRoute={ROUTES.FORMS.IMPORT_DATA.GRANTS.IMPORT}
      sessionRoute={ROUTES.FORMS.IMPORT_DATA.GRANTS.SESSION}
      category="equity_grants_bulk_upload"
      entity="grant"
      name="grant"
      row={row}
      tableName={TableNames.UploadGrants}
      templateParams={{ version: '2' }}
      header={<PageHeader backUrl={ROUTES.MAIN} title="Import grants" />}
    />
  )
}
